//import { Link, FormattedMessage } from "gatsby-plugin-intl"
import localize from "../components/localize"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import Img from "gatsby-image"
import React from "react"
import { kebabCase } from "lodash"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"

export const query = graphql`
  query ($slug: String) {
    sanityCategory(slug: { current: { eq: $slug } }) {
      title {
        _type
        es
        pt
        en
      }
      image {
        asset {
          url
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      description {
        _type
        es
        pt
        en
      }
    }
    allSanityPost(
      filter: {
        categories: { elemMatch: { slug: { current: { eq: $slug } } } }
      }
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          title
          _rawExcerpt(resolveReferences: { maxDepth: 10 })
          _rawArticle(resolveReferences: { maxDepth: 10 })
          categories {
            title {
              _type
              es
              pt
              en
            }
            slug {
              current
            }
            _id
          }
          mainImage {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          publishedAt(locale: "es", formatString: "LL")
          slug {
            current
          }
          _createdAt(locale: "es", formatString: "LL")
          author {
            name
          }
        }
      }
    }
  }
`

const CategoriesTemplatePage = ({ data }) => (
  <Layout>
    <Helmet>
      <body className="invert-nav" />
    </Helmet>
    <SEO
      title={data.sanityCategory.title}
      image={data.sanityCategory.image.asset.url}
    />
    <div className="flex flex-col mt-24">
      <div className="relative z-10 flex flex-col items-center justify-center w-full px-6 pt-12 mx-auto mb-6 text-center bg-gray-100 md:mb-0 md:py-24 md:px-24">
        <Link
          to="/blog"
          className="inline-block mb-6 font-serif text-gray-800 border-b-2 border-gray-700"
        >
          Ver todo el blog
        </Link>
        <h1 className="font-serif text-4xl text-gray-900">
          {data.sanityCategory.title}
        </h1>
        <div className="block mt-6 font-sans prose prose-xl text-center text-gray-900 ">
          {data.sanityCategory.description && (
            <>{data.sanityCategory.description}</>
          )}
        </div>
      </div>
      <Section>
        <div className="grid gap-2 px-6 py-6 md:grid-cols-2">
          {data.allSanityPost.edges.map(({ node: article }) => (
            <Link
              to={`/blog/${kebabCase(article.slug.current)}`}
              className="relative flex items-center justify-center h-64 overflow-hidden transition-all duration-700 ease-in-out transform bg-gray-900 blog-item"
            >
              <figure className="absolute inset-0 w-full h-64 overflow-hidden opacity-30 ">
                {article.mainImage && (
                  <Img
                    fluid={article.mainImage.asset.fluid}
                    className="w-full h-64 object-full"
                  />
                )}
              </figure>
              <div className="relative z-50 w-full px-3 ">
                <h4 className="px-6 mt-2 font-sans text-xl font-bold tracking-wider text-center text-white no-underline transition-all duration-500 transform-gpu md:text-3xl hover:opacity-70">
                  {article.title}
                </h4>
                <div className="flex-col items-center justify-center hidden mt-4">
                  {article.categories.map(({ title, slug, _id }) => (
                    <Link
                      key={_id}
                      className="inline-block mb-1 font-mono font-bold uppercase transition-all duration-500 transform-gpu hover:opacity-80 hover:text-gray-300"
                      to={`/categories/${kebabCase(slug.current)}`}
                    >
                      « {title} »
                    </Link>
                  ))}
                </div>
                <p className="hidden italic text-center text-white ">
                  {article._createdAt}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </Section>
    </div>
  </Layout>
)

export default localize(CategoriesTemplatePage)

const Section = styled.section`
  ${tw`relative w-full max-w-6xl mx-auto overflow-y-auto`}

  .blog-item {
    img {
      transition: all 700ms !important;
      transform: scale(1) !important;
    }

    &:hover {
      img {
        transform: scale(1.05) !important;
      }
    }
  }
`
